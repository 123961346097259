import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { Grid } from "@mui/material";

import { CalculatorsContext } from "../../../context";
import {
  CALC_TYPES,
  CalculatorsListData,
  ChartSectionsMappings,
} from "../../../constants";

import NotFound from "../../../views/notFound/NotFound";

import LocalCalculator from "../Local/Local";
import CompanyCalculator from "../Carbon/Company";
import GlobalCalculator from "../Global/Global";

import Chart from "../../../components/chart/Chart";
import ProgressCircle from "../../../components/progressCircle/ProgressCircle";
import AccordionGroup from "../../../components/accordionGroup/AccordionGroup";
import SelfAssessment from "../../../components/selfAssessment/SelfAssessment";
import SolutionsGlobalModal from "../../../components/solutionsModal/SolutionsGlobalModal.jsx";
import SolutionsModal from "../../../components/solutionsModal/SolutionsModal";

import getCalculatorDescription from "../descriptions";
import styles from "./CalculatorsRouter.module.scss";
import CustomReport from "../../reports/CustomReport/CustomReport.jsx";
import CustomFiles from "../../reports/CustomFiles/CustomFiles.jsx";

const CalculatorsRouter = () => {
  const navigate = useNavigate();
  /* TODO сделать переход на последний вопрос */
  // const { pathname, state } = useLocation();
  const { pathname } = useLocation();
  const calculatorContainer = useRef();
  const {
    questions,
    carbonSources,
    chartData,
    lastVisitedIndex,
    selectedCalculators,
    changeLastVisitedIndex,
    calculatorName,
    companyQuestions,
    setCurrentCalcSection,
    section,
  } = useContext(CalculatorsContext);
  const [previousCount, setPreviousCount] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionCount, setQuestionCount] = useState(1);
  // const [chartIndex, setChartIndex] = useState(null);
  const [chartColor, setChartColor] = useState(null);
  const [chartName, setChartName] = useState(null);
  const [chartPartData, setChartPartData] = useState([]);

  const [accordionItems, setAccordionItems] = useState([]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activeSector, setActiveSector] = useState(null);

  const openModal = (activeItem) => {
    setActiveSector(activeItem);
    setIsModalOpened(true);
  };
  const closeModal = () => {
    setActiveSector(null);
    setIsModalOpened(false);
  };

  useEffect(() => {
    const sectionList = [
      { k: "scope1", v: "scope_1" },
      { k: "scope2", v: "scope_2" },
      { k: "scope3", v: "scope_3" },
      { k: "global", v: "global" },
      { k: "local", v: "local" },
    ];
    const newSection = sectionList.find((el) => pathname.includes(el.k))?.v;
    setCurrentCalcSection({ section: newSection });
  }, [pathname]);

  useEffect(() => {
    const availableCalculators = CalculatorsListData[calculatorName].children;
    if (availableCalculators && availableCalculators.length) {
      const isExists = availableCalculators.some((url) =>
        pathname.includes(url),
      );
      if (!isExists) navigate("/drafts");
    }
  }, [calculatorName, pathname]);

  useEffect(() => {
    if (
      calculatorName === CALC_TYPES.GLOBAL ||
      calculatorName === CALC_TYPES.CUSTOM_REPORT ||
      calculatorName === CALC_TYPES.CUSTOM_FILES
    )
      return;

    const isESG = pathname.includes("/local/");
    if (!isESG) {
      const descriptions = getCalculatorDescription({ pathname });
      setAccordionItems(descriptions);
    } else if (isESG && questions && questions.length) {
      const newIndex = parseInt(pathname.replace(/^.*[\\/]/, ""), 10) - 1;
      const question = questions[newIndex];
      const descriptions = getCalculatorDescription({ pathname, question });

      setQuestionIndex(newIndex);
      setAccordionItems(descriptions);
    }
  }, [questions, pathname]);

  useEffect(() => {
    if (!calculatorName || calculatorName === "global") return;
    const calculators = selectedCalculators.map((c) => c.name);
    const companyCalc = calculators.includes("scope3")
      ? companyQuestions.reduce((res, q) => res + q.scope.length, 0)
      : 0;

    const localCalc = calculators.includes("local") ? questions.length : 0;

    const currentIndex = calculators.indexOf(
      calculators.find((c) => pathname.includes(c)),
    );

    setPreviousCount(0);
    if (
      calculators.includes("scope3") &&
      calculators.indexOf("scope3") < currentIndex
    ) {
      setPreviousCount((value) => value + companyCalc);
    }
    if (
      calculators.includes("local") &&
      calculators.indexOf("local") < currentIndex
    ) {
      setPreviousCount((value) => value + localCalc);
    }
    setQuestionCount(companyCalc + localCalc);
  }, [carbonSources, questions, questionIndex]);

  useEffect(() => {
    if (
      calculatorName === CALC_TYPES.GLOBAL ||
      calculatorName === CALC_TYPES.CUSTOM_REPORT ||
      calculatorName === CALC_TYPES.CUSTOM_FILES
    )
      return;
    if (!accordionItems || !accordionItems.length) {
      changeLastVisitedIndex(-1);
      return;
    }

    const { name } = accordionItems[accordionItems.length - 1];
    const index = chartData.findIndex(
      (cd) => cd.name === (ChartSectionsMappings[name] || name),
    );

    if (index - 1 > lastVisitedIndex) changeLastVisitedIndex(index - 1);
  }, [accordionItems]);

  const onAccordionSelect = (selected, name) => {
    const items = [...accordionItems];
    const oldActive = items.findIndex((v) => v.expanded);

    if (oldActive !== -1) items[oldActive].expanded = false;

    if (selected) {
      const newActive = items.findIndex((v) => v.name === name);

      if (newActive !== -1) items[newActive].expanded = true;
    }
    setAccordionItems(items);
  };

  useEffect(() => {
    if (!section) return;
    const data = {};
    if (chartData.some((c) => section === c.type)) {
      if (pathname.includes("global")) {
        const reg = /\/global\/(\d+)/;
        const match = pathname.match(reg);
        data.chart = chartData[match[1] - 1].children;
      } else {
        data.chart = chartData.filter((c) => section === c.type);
        if (pathname.includes("local")) {
          const question = questions[questionIndex];
          data.chart = data.chart.filter((c) =>
            question.categories.includes(c.name),
          );
        }
      }
    } else {
      data.chart = chartData;
    }
    if (data.chart.length) {
      setChartName(data.chart[0].name);
      setChartColor(data.chart[0].color);
    }
    setChartPartData(data.chart);
  }, [pathname, chartData, questionIndex, questions, section]);

  const getFirstQuestionUrl = () => {
    if (pathname.includes("/local/")) {
      const qIndex = questions.findIndex(
        (q) => q.categories[0] === accordionItems[0].name,
      );
      return `/calculators/local/${qIndex + 1}`;
    }
    if (pathname.includes("/global/")) {
      // TODO: переделать, из аккордеона надо получать дименшн, категори, индекс
      return `/calculators/global/1/1/1`;
    }
    return selectedCalculators && selectedCalculators.length
      ? selectedCalculators[0].url
      : "/";
  };

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        className={styles.calculatorContainer}
        ref={calculatorContainer}
      >
        <Grid className={styles.breadcrumps}>
          TransparenTerra&nbsp;&nbsp;▷&nbsp;&nbsp;ESG&nbsp;&nbsp;▷&nbsp;&nbsp;
          <Link to="/">Calculator</Link>
          {accordionItems && accordionItems.length && (
            <span>
              &nbsp;&nbsp;▷&nbsp;&nbsp;
              <Link to={getFirstQuestionUrl()}>{accordionItems[0].name}</Link>
            </span>
          )}
        </Grid>
        <SelfAssessment />

        <Grid item container justifyContent="center" className={styles.content}>
          <ProgressCircle
            current={questionIndex + previousCount}
            count={questionCount}
            color={chartColor}
          />

          {calculatorName !== CALC_TYPES.CUSTOM_REPORT &&
            calculatorName !== CALC_TYPES.CUSTOM_FILES && (
              <>
                <div className={styles.chartArea}>
                  <Chart
                    chartData={chartPartData}
                    hasDesktopFormat={false}
                    onChartClick={() => openModal(chartName)}
                    activeItem={activeSector}
                  />
                </div>
                <Grid
                  item
                  container
                  alignItems="center"
                  className={styles.infoArea}
                >
                  <AccordionGroup
                    items={accordionItems}
                    onAccordionClick={onAccordionSelect}
                  />
                </Grid>
              </>
            )}

          <Routes>
            <Route exact path="/local/:idx" element={<LocalCalculator />} />
            <Route
              exact
              path="/carbon/company/scope1"
              element={<Navigate to="1" />}
            />
            <Route
              exact
              path="/carbon/company/scope2/"
              element={<Navigate to="1" />}
            />
            <Route
              exact
              path="/carbon/company/scope3/"
              element={<Navigate to="1" />}
            />
            <Route
              exact
              path="/carbon/company/scope1/:idx"
              element={
                <CompanyCalculator
                  scope="1"
                  setQuestionIndex={setQuestionIndex}
                />
              }
            />
            <Route
              exact
              path="/carbon/company/scope1/:idx"
              element={
                <CompanyCalculator
                  scope="1"
                  setQuestionIndex={setQuestionIndex}
                />
              }
            />
            <Route
              exact
              path="/carbon/company/scope2/:idx"
              element={
                <CompanyCalculator
                  scope="2"
                  setQuestionIndex={setQuestionIndex}
                />
              }
            />
            <Route
              exact
              path="/carbon/company/scope3/:idx"
              element={
                <CompanyCalculator
                  scope="3"
                  setQuestionIndex={setQuestionIndex}
                />
              }
            />
            <Route
              exact
              path="/global/:dimUrl/:catUrl/:idxUrl"
              element={
                <GlobalCalculator
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            <Route
              exact
              path="/custom_report/:pageNum"
              element={
                <CustomReport
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            <Route
              exact
              path="/custom_files"
              element={
                <CustomFiles
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Grid>
      </Grid>
      {calculatorName === "global" && (
        <SolutionsGlobalModal
          opened={isModalOpened}
          closeModalFunc={closeModal}
          activeItem={activeSector}
        />
      )}
      {calculatorName !== "global" && (
        <SolutionsModal
          opened={isModalOpened}
          closeModalFunc={closeModal}
          activeItem={activeSector}
        />
      )}
    </>
  );
};

export default CalculatorsRouter;
