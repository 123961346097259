import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import UiTextField from "../../../../components/uiTextField/UiTextField";
import MuiSelect from "../../../../components/muiSelect/MuiSelect";
import MuiCheckBox from "../../../../components/muiCheckBox/MuiCheckBox";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/delete.svg";

import {
  CompanyQuestionsEnabledFields,
  NumberFilter,
} from "../../../../constants";
import styles from "../index.module.scss";

const Component6Fields = ({
  component,
  name,
  index,
  typeName,
  isLast,
  onChange,
  onRemove,
  getItemsList,
  getSingleItem,
  isSingleItem,
  label,
}) => {
  const getIdValue = (input, itemName) => {
    if (!itemName) return "";
    const items = getItemsList(component, input);
    return items.length > 0
      ? items.find((item) => item.name === itemName).id
      : "";
  };

  const getNamesList = (input) => {
    const items = getItemsList(component, input);
    return items.map((item) => item.name);
  };

  const getInputValue = (input, id) => {
    if (!id) return "";
    const items = getItemsList(component, input);
    const val = items.find((item) => item.id === id)?.name;
    return val ?? "";
  };

  const handleChange = (e) => {
    const targetName = e.target.name;
    const value =
      targetName !== "data" && targetName !== "info"
        ? getIdValue(e.target.name, e.target.value)
        : e.target.value;
    onChange(name, index, targetName, value);
  };

  const handleCheckBoxChange = (e) => {
    onChange(name, index, e.target.name, !component.isNotAvailable);
  };

  const getStyle = () => {
    const { length } = CompanyQuestionsEnabledFields[name];
    switch (length) {
      case 1:
        return styles.col2;
      case 2:
        return styles.col3;
      case 3:
        return styles.col4;
      case 4:
        return styles.col3;
      case 5:
        return styles.col3;
      default:
        return styles.col2;
    }
  };
  return (
    <>
      <div className={clsx([styles.componentInputs, getStyle(), "activity"])}>
        <div className={styles.componentLabel}>
          Activity №{index + 1}
          <span>
            {index !== 0 && <DeleteSvg onClick={() => onRemove(index)} />}
          </span>
        </div>
        {CompanyQuestionsEnabledFields[name].includes("activity") && (
          <MuiSelect
            className={styles.componentInput}
            label={label !== "" ? label : "Activity"}
            name="activity"
            value={getInputValue("activity", component.activity)}
            onChange={handleChange}
            items={getNamesList("activity")}
          />
        )}
        {CompanyQuestionsEnabledFields[name].includes("type") && (
          <MuiSelect
            className={styles.componentInput}
            label={typeName}
            name="type"
            value={getInputValue("type", component.type)}
            onChange={handleChange}
            items={getNamesList("type")}
            disabled={
              !component.activity &&
              CompanyQuestionsEnabledFields[name].includes("activity")
            }
          />
        )}
        {CompanyQuestionsEnabledFields[name].includes("unit") && (
          <MuiSelect
            className={styles.componentInput}
            label="Units"
            name="unit"
            value={getInputValue("unit", component.unit)}
            onChange={handleChange}
            items={getNamesList("unit")}
            disabled={
              !component.type &&
              CompanyQuestionsEnabledFields[name].includes("type")
            }
          />
        )}
        {CompanyQuestionsEnabledFields[name].includes("size") && (
          <MuiSelect
            className={styles.componentInput}
            label={"Size"}
            name="size"
            value={getInputValue("size", component.size)}
            onChange={handleChange}
            items={getNamesList("size")}
            disabled={!component.unit}
          />
        )}
        {CompanyQuestionsEnabledFields[name].includes("additional_s") && (
          <MuiSelect
            className={styles.componentInput}
            label="Additional"
            name="additional_s"
            value={getInputValue("additional_s", component.additional)}
            onChange={handleChange}
            items={getNamesList("additional_s")}
            disabled={!component.size}
          />
        )}
        {CompanyQuestionsEnabledFields[name].includes("additional_u") && (
          <MuiSelect
            className={styles.componentInput}
            label="Additional"
            name="additional_u"
            value={getInputValue("additional_u", component.additional)}
            onChange={handleChange}
            items={getNamesList("additional_u")}
            disabled={!component.unit}
          />
        )}
        <UiTextField
          className={styles.componentInput}
          name="data"
          label={`Enter your data${
            isSingleItem(component, "unit")
              ? `, ${getSingleItem(component, "unit").name}`
              : ""
          }`}
          value={component.data}
          onChange={handleChange}
          filter={NumberFilter}
          inputProps={{ maxLength: 20 }}
        />
      </div>
      <div
        className={clsx(
          styles.isNotAvailable,
          isLast && !component.isNotAvailable
            ? styles.partWidth
            : styles.fullWidth,
          component.isNotAvailable && styles.selected,
        )}
      >
        <MuiCheckBox
          label={"This information is not available"}
          className={styles.checkbox}
          value={component.isNotAvailable}
          onChange={handleCheckBoxChange}
          name="isNotAvailable"
        />
        {component.isNotAvailable && (
          <UiTextField
            className={styles.info}
            name="info"
            label={"Information"}
            value={component.info}
            onChange={handleChange}
            required={false}
            inputProps={{ maxLength: 255 }}
          />
        )}
      </div>
    </>
  );
};

Component6Fields.propTypes = {
  component: PropTypes.object,
  name: PropTypes.string,
  index: PropTypes.number,
  units: PropTypes.string,
  typeName: PropTypes.string,
  types: PropTypes.string,
  isLast: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  getItemsList: PropTypes.func,
  enabledFields: PropTypes.array,
  getSingleItem: PropTypes.func,
  isSingleItem: PropTypes.func,
  label: PropTypes.string,
};

Component6Fields.defaultProps = {
  name: "",
  index: 0,
  units: "",
  typeName: "",
  types: "",
  isLast: false,
  enabledFields: [],
  label: "",
  onChange: () => {},
  onRemove: () => {},
  getItemsList: () => {},
  getSingleItem: () => {},
  isSingleItem: () => false,
};

export default Component6Fields;
