import React, { useContext, useState } from "react";

import Modal from "../CalcModal/CalcModal";

import styles from "./SelfAssessment.module.scss";

import { ReactComponent as HelpSvg } from "../../assets/icons/help.svg";
import { SelfAssessmentDisclaimerText, isESG } from "../../constants";
import { CalculatorsContext } from "../../context";

const SelfAssessment = () => {
  const { calculatorName } = useContext(CalculatorsContext);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);

  const openDisclaimer = () => {
    setIsDisclaimerOpened(true);
  };
  const closeDisclaimer = () => {
    setIsDisclaimerOpened(false);
  };

  const getTextHeader = () =>
    isESG(calculatorName)
      ? "You are running a self-assessment"
      : "AI-generated content";

  const getDisclaimer = () =>
    SelfAssessmentDisclaimerText[calculatorName]
      ? SelfAssessmentDisclaimerText[calculatorName]
      : SelfAssessmentDisclaimerText.base;

  return (
    <>
      <div className={styles.selfAssessment}>
        <button onClick={openDisclaimer}>
          <HelpSvg></HelpSvg>
        </button>
        <span onClick={openDisclaimer}>{getTextHeader()}</span>
      </div>
      <Modal
        opened={isDisclaimerOpened}
        closeModalFunc={closeDisclaimer}
        headerContent="Disclaimer"
        okBtnText="Ok"
        needAgreement={false}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: getDisclaimer(),
          }}
        />
      </Modal>
    </>
  );
};

export default SelfAssessment;
