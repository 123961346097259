import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { TextInput } from "tt-ui-kit";

const UiTextField = ({
  name,
  label,
  value,
  onChange,
  filter,
  className,
  required,
  sx,
  tooltipText,
  startIcon,
  endIcon,
  onClick,
  readOnly,
  blockWrongTyping,
  disabled,
  isNumber,
  min,
  max,
  step,
  allowDecimal,
  ...restProps
}) => {
  const [isValidated, setIsValidated] = useState(true);
  const [inputValue, setInputValue] = useState(value);
  const [timeoutID, setTimeoutID] = useState(null);

  useEffect(() => setInputValue(value), [value]);

  const DebouncedChange = (e) => {
    if (!e?.target) return;
    onChange(e);
  };

  const handleChange = (e) => {
    console.log("e.target.value", e.target.value);
    // if (blockWrongTyping && filter && !filter.test(e.target.value)) {
    //   // if (e.target.value === "") {
    //   //   setInputValue("");
    //   // }
    //   return;
    // }
    setIsValidated(filter ? filter.test(e.target.value) : true);
    setInputValue(e.target.value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const newTimeoutId = setTimeout(() => {
      DebouncedChange(e);
    }, 300);
    setTimeoutID(newTimeoutId);
  };

  const getHelperText = () => {
    if (!isValidated) return "Incorrect value";
    return "";
  };

  return (
    <TextInput
      className={className}
      tooltipText={tooltipText}
      label={label}
      name={name}
      value={inputValue ?? ""}
      onChange={handleChange}
      helperText={getHelperText()}
      error={!isValidated}
      sx={sx}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      readOnly={readOnly}
      required={required}
      disabled={disabled}
      isNumber={isNumber}
      min={min}
      max={max}
      step={step}
      allowDecimal={allowDecimal}
      {...restProps}

      // filter,
    />
  );
};

UiTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  filter: PropTypes.instanceOf(RegExp),
  sx: PropTypes.object,
  tooltipText: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  readOnly: PropTypes.bool,
  valueLength: PropTypes.number,
  blockWrongTyping: PropTypes.bool,
  disabled: PropTypes.bool,
  isNumber: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  allowDecimal: PropTypes.bool,
};

UiTextField.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  onClick: () => {},
  className: "",
  filter: null,
  required: true,
  sx: null,
  tooltipText: "",
  readOnly: false,
  blockWrongTyping: true,
};

export default UiTextField;
