import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_DRAFT_BY_ID } from "../../api";
import { CalculatorsContext, ErrorContext, UserContext } from "../../context";
import { isDraftFilled } from "../../utils";

const EditAssessment = () => {
  const navigate = useNavigate();
  const { draftId } = useParams();

  const { selectedCalculators, createCalculatorList } =
    useContext(CalculatorsContext);
  const { setDraftId, prepareDraft } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);
  const [draft, setDraft] = useState(null);

  const { data: draftData } = useQuery(GET_DRAFT_BY_ID, {
    variables: { id: draftId },
  });

  useEffect(() => {
    window.sessionStorage.removeItem("SELECTED_DRAFT_ID");
    setDraftId(null);
  }, []);

  useEffect(() => {
    if (!draftData) return;
    if (!draftData.draftById) {
      setErrorAlert("Draft was not found", () =>
        navigate("/drafts", { replace: true }),
      );
    }
    const newDraft = prepareDraft(draftData.draftById);
    setDraft({ ...newDraft });
    setDraftId(draftId);
  }, [draftData]);

  useEffect(() => {
    if (draft) {
      const name = draft.type;
      if (name) {
        createCalculatorList(name);
      }
    }
  }, [draft]);

  useEffect(() => {
    if (!selectedCalculators || !selectedCalculators.length || !draft) return;
    if (draft && isDraftFilled(draft)) {
      if (draft.lastPosition) navigate(draft.lastPosition);
      else navigate(selectedCalculators[0].url, { replace: true });
    } else navigate("/registration", { replace: true });
  }, [selectedCalculators, draft]);

  return <></>;
};

export default EditAssessment;
