import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useMutation } from "@apollo/client";
import { CheckBoxOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CalculatorsContext, UserContext } from "../../context";
import { UPDATE_DRAFT, GET_PRELIMINARY_ASSESSMENT } from "../../api";

import Chart from "../../components/chart/Chart";

import styles from "./finish.module.scss";
import CalcModal from "../../components/CalcModal/CalcModal";
import { formatDate } from "../../utils";

const Finish = () => {
  const navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);
  const [updateDraft, { data: updatedData }] = useMutation(UPDATE_DRAFT);
  const [getResultDraft, { data: preCalculate }] = useMutation(
    GET_PRELIMINARY_ASSESSMENT,
  );
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const endpointMain = process.env.REACT_APP_SSO_HOST || "";
  const redirectMain = (url) => {
    window.location.href = `${endpointMain}${url}`;
  };

  const { chartData, score, setScore, lastVisitedIndex, calculatorName } =
    useContext(CalculatorsContext);
  const { draftId, draftsList } = useContext(UserContext);

  const scrolledToEnd = (e) => {
    const { target } = e;
    setIsCheckboxEnabled(
      target.offsetHeight + Math.ceil(target.scrollTop) >= target.scrollHeight,
    );
  };

  const lastUpdate = () => {
    const drft = draftsList.filter((d) => d.id === draftId);
    window.sessionStorage.setItem("draft", JSON.stringify(drft)); // TODO remove - for debug

    updateDraft({
      variables: {
        input: {
          id: draftId,
          state_graphics: JSON.stringify(chartData),
          completed_at: formatDate(new Date()),
        },
      },
    });
  };

  useEffect(() => {
    if (!updatedData) return;
    getResultDraft({ variables: { id: draftId } });
  }, [updatedData]);

  useEffect(() => {
    if (!draftId) return;
    lastUpdate();
  }, []);

  const getTotal = (data) => {
    const {
      environmental,
      social,
      governance,
      productTotal,
      packageTotal,
      companyTotal,
      globalTotal,
    } = data;

    switch (calculatorName) {
      case "local":
        return (
          parseFloat(environmental) +
          parseFloat(social) +
          parseFloat(governance)
        );
      case "global":
        return parseFloat(globalTotal || 0);
      case "package":
      case "product":
      case "company":
      case "carbon":
        return (
          parseFloat(productTotal || 0) +
          parseFloat(packageTotal || 0) +
          parseFloat(companyTotal || 0)
        );
      default:
        return -1;
    }
  };

  useEffect(() => {
    if (preCalculate && preCalculate.preliminaryAssessment) {
      const {
        environmental,
        social,
        governance,
        productTotal,
        packageTotal,
        companyTotal,
        globalTotal,
        testYear,
      } = preCalculate.preliminaryAssessment;
      const value = getTotal(preCalculate.preliminaryAssessment);

      setScore({
        environmental: parseFloat(environmental),
        social: parseFloat(social),
        governance: parseFloat(governance),
        total: Math.round(value * 100) / 100,
        productTotal: productTotal ? parseFloat(productTotal) : 0,
        packageTotal: packageTotal ? parseFloat(packageTotal) : 0,
        companyTotal: companyTotal ? parseFloat(companyTotal) : 0,
        globalTotal: globalTotal ? parseFloat(globalTotal) : 0,
        testYear,
      });
    }
  }, [preCalculate]);

  return (
    <>
      <h1 className={styles.finishHeader}>
        Release and Send to the Blockchain
      </h1>
      <Grid
        item
        container
        alignItems="stretch"
        justifyContent="center"
        className={styles.container}
      >
        {windowWidth.current > 1024 && (
          <div className={styles.chartArea}>
            <Chart
              chartData={chartData}
              score={score.total.toString()}
              hasDesktopFormat
              lastIndex={lastVisitedIndex}
            />
          </div>
        )}
        <Grid item container direction="row" className={styles.textArea}>
          <div className={styles.owerflowContainer} onScroll={scrolledToEnd}>
            <div className={styles.text}>
              {windowWidth.current <= 1024 && (
                <div className={styles.chartArea}>
                  <Chart
                    chartData={chartData}
                    score={score.total.toString()}
                    hasDesktopFormat
                    lastIndex={lastVisitedIndex}
                  />
                </div>
              )}
              {calculatorName === "local" && (
                <>
                  {`Based on your self-assessment, your Local TT ESG score for the
                  year ${score.testYear} is ${score.total}.`}
                  <br />
                </>
              )}
              {(calculatorName === "carbon" ||
                calculatorName === "product" ||
                calculatorName === "package" ||
                calculatorName === "company") && (
                <>
                  {`You have completed your calculations.`}
                  <br />
                  {`The carbon footprint for year ${score.testYear} is: 
                  Product carbon footprint ${score.productTotal}
                  Packaging carbon footprint ${score.packageTotal} 
                  Company carbon footprint ${score.companyTotal}.`}
                </>
              )}
              {calculatorName === "global" && (
                <>
                  {`You have completed your calculations.`}
                  <br />
                  {`The TT.Global Calculator result for year ${score.testYear} is: 
                    ${score.globalTotal}.`}
                </>
              )}
              <div>
                <br />
                <strong>
                  Your self-assessment results will be encrypted, and the hash
                  will be deployed and notarized on the FCE Blockchain.It is a
                  paid option. Please proceed with payment to get access to your
                  assessment.
                </strong>
              </div>
              <h3>Disclaimer</h3>
              <div>
                To become visible to interested investors, you need to submit
                the data to the blockchain.
                <br />
                The data will be sent to FCE Group&apos;s blockchain via the
                TransparenTerra platform.
                <br />
                We will not send raw data to the blockchain, only data hashes.
                All your data will be stored on the profile page, hashed there
                and sent to the blockchain in encrypted form. If someone wants
                to compare or verify the data on the blockchain, they will
                compare the hashes, not the data itself. The hash is generated
                on the profile page.
                <br />
                The responsibility for the quality of the data lies solely with
                those who enter the data. The data in the blockchain is
                immutable and will be stored forever. To respect your rights
                under GDPR, we will send hashes of your ESG score results there.
                In your profile, experts, investors and any interested parties
                who have access to this data can compare the validity of your
                data with the hashes.
                <br />
                The results sent to the blockchain contribute to the full
                transparency of your company and become attractive for
                investment.
                <br />
                All blockchain transactions are public and chargeable. Once
                published, the data cannot be deleted or changed, but you can
                always complete a new calculation and publish the updated
                results.
              </div>
              <div className={styles.agreeCheck}>
                <div className={styles.checkboxContainer}>
                  <FormControlLabel
                    className={styles.checkbox}
                    control={
                      <Checkbox
                        onChange={() => setIsAgreed(!isAgreed)}
                        checkedIcon={<CheckBoxOutlined />}
                        // variant="unlighted"
                        disabled={
                          !isCheckboxEnabled && windowWidth.current >= 1024
                        }
                      />
                    }
                    label="I have read the terms and conditions and agree to them"
                    labelPlacement="end"
                  />
                </div>
              </div>
            </div>
          </div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Button form="rounded" variant="outlined" onClick={() => {}}>
              Skip
            </Button>
            <Button
              form="rounded"
              variant="contained"
              onClick={() => redirectMain(`/dashboard/buy_credits/${draftId}`)}
              disabled={!isAgreed}
            >
              Release
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CalcModal
        opened={isModalOpened}
        closeModalFunc={() => setIsModalOpened(false)}
        okFunc={() => {
          navigate(`/buy_credits/${draftId}`);
        }}
        backFunc={() => {
          navigate("/completed");
        }}
        headerContent="Congratulations!"
        okBtnText="Buy credits"
        backBtnText="My Assessment"
        containedStyle={true}
      >
        <div>You payment has been successfully processed.</div>
      </CalcModal>
    </>
  );
};

Finish.propTypes = { dontHaveClick: PropTypes.func, addClick: PropTypes.func };

Finish.defaultProps = { dontHaveClick: () => {}, addClick: () => {} };

export default Finish;
