/* eslint-disable */
import React from 'react';
import { Disconnect } from './components/Disconnect';

export const DisconnectHandler = ({
  logoutHandler,
  logoutDelay,
  accessToken,
  location,
  userId,
  getUser,
  domainValue,
}) => (
  <Disconnect
    location={location}
    userId={userId}
    logoutHandler={logoutHandler}
    accessToken={accessToken}
    logoutDelay={logoutDelay}
    getUser={getUser}
    domainValue={domainValue}
  />
);
