import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { Button, Grid } from "@mui/material";
import Chart from "../../components/chart/Chart";
import AccordionGroup from "../../components/accordionGroup/AccordionGroup";
import { CalculatorsContext } from "../../context";

import { ReactComponent as CloseSvg } from "../../assets/icons/close.svg";
import styles from "./SolutionsModal.module.scss";

const SolutionsGlobalModal = ({ activeItem, opened, closeModalFunc }) => {
  const { chartData, lastVisitedIndex, globalQuestions } =
    useContext(CalculatorsContext);

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(activeItem);
  const [chart, setChart] = useState([]);

  useEffect(() => {
    setSelectedItem(activeItem);
  }, [activeItem]);

  useEffect(() => {
    setChart(chartData);
  }, [chartData]);

  const onCloseClick = () => {
    setSelectedItem(null);
    closeModalFunc();
  };

  const chartMainReturn = () => {
    setSelectedItem(null);
  };

  useEffect(() => {
    if (!selectedItem) {
      setChart(chartData);
    } else {
      const newChart = chartData.find(
        (dim) =>
          dim.name === selectedItem ||
          dim.children.some((cat) => cat.name === selectedItem),
      )?.children;
      setChart(newChart);
    }
    const newItems = [
      ...items.map((dim) => ({
        ...dim,
        children: [
          ...dim.children.map((cat) => ({
            ...cat,
            expanded: cat.name === selectedItem,
          })),
        ],
        expanded:
          dim.name === selectedItem ||
          dim.children.some((cat) => cat.name === selectedItem),
      })),
    ];
    setItems(newItems);
  }, [selectedItem]);

  const setNewSelected = (selected, clicked) => {
    const newSelected = selected
      ? clicked
      : chartData.find((dim) =>
          dim.children.some((cat) => cat.name === clicked),
        )?.name;
    setSelectedItem(newSelected);
  };

  const onChartSectorClick = (active, clicked) => {
    if (!clicked) return;
    setNewSelected(active === clicked, clicked);
  };

  const onAccordionClick = (selected, clicked) => {
    if (!clicked) return;
    setNewSelected(selected, clicked);
  };

  const setInitAccordionItems = () => {
    const accordionItems = globalQuestions.map((dim) => ({
      expanded:
        dim.name === activeItem ||
        dim.categories.some((cat) => cat.name === activeItem),
      name: dim.name,
      content: dim.description,
      children: dim.categories.map((cat) => ({
        expanded: cat.name === activeItem,
        name: cat.name,
        content: cat.description,
      })),
    }));
    setItems(accordionItems);
  };

  useEffect(() => {
    setInitAccordionItems();
  }, [opened]);

  return (
    <>
      {opened && (
        <div className={styles.modal}>
          <Button
            variant="text"
            className={styles.closeBtn}
            onClick={onCloseClick}
          >
            <CloseSvg className={styles.closeSvg} />
          </Button>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            className={styles.content}
          >
            <div className={styles.chartArea}>
              <Chart
                chartData={chart}
                activeItem={selectedItem}
                onSectorClick={onChartSectorClick}
                hasDesktopFormat
                lastIndex={lastVisitedIndex}
                onCenterClick={chartMainReturn}
              />
            </div>
            <div className={styles.accordionArea}>
              <AccordionGroup
                items={items}
                onAccordionClick={onAccordionClick}
              />
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

SolutionsGlobalModal.propTypes = {
  activeItem: PropTypes.string,
  opened: PropTypes.bool,
  closeModalFunc: PropTypes.func,
};

SolutionsGlobalModal.defaultProps = {
  activeItem: "",
  opened: false,
  closeModalFunc: () => {},
};

export default SolutionsGlobalModal;
