/* eslint-disable camelcase */
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, TextInput } from "tt-ui-kit";
import { Button } from "@mui/material";
import FileUploader from "../../../components/fileUploader/FileUploader";
import styles from "./ModalQuestion.module.scss";

const ModalCreateQuestion = ({ params }) => {
  const {
    isOpen,
    close,
    isGenerating,
    editQuestionId,
    parentTopicId,
    addItem,
    changeQuestion,
    saveDraftData,
    generateAnswer,
  } = params;
  const [editQuestionText, setEditQuestionText] = useState("");
  const [edited, setEdited] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    setEditQuestionText("");
    setEdited(false);
  }, []);

  const onSave = () => {
    setEdited(false);
    if (!editQuestionText) return;

    if (!editQuestionId)
      addItem({
        typeItem: "question",
        parentId: parentTopicId,
        payload: { question_text: editQuestionText },
      });
    else {
      changeQuestion({
        id: editQuestionId,
        question_text: editQuestionText,
      });
      setUpdated(true);
    }
  };

  useEffect(() => {
    if (!updated) return;
    saveDraftData(editQuestionId);
  }, [updated]);

  const onEdit = (value) => {
    setEdited(true);
    setEditQuestionText(value);
  };

  const closeClick = () => {
    setUpdated(false);
    setEdited(false);
    setEditQuestionText("");
    close();
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeClick}
      closeOnlyByControls
      title={
        <div className={styles.headerModal}>
          <div>Add question</div>
        </div>
      }
      className={styles.modal}
      /* eslint-disable-next-line no-underscore-dangle */
      containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
    >
      <div className={styles.modalContent}>
        <TextInput
          name="question"
          label="Question"
          className={styles.questionInput}
          inputProps={{ maxLength: 1800 }}
          value={editQuestionText}
          onChange={(e) => onEdit(e.target.value)}
          multiline
          maxRows={10}
          minRows={3}
        />
      </div>
      <div className={styles.modalButtonsBlock}>
        <Button variant="outlined" onClick={closeClick}>
          Cancel
        </Button>
        <FileUploader
          questionCode={editQuestionId ?? ""}
          btnText={"Add documents"}
          btnType={"default"}
          disabled={!editQuestionId}
        />
        <Button
          variant="outlined"
          onClick={() =>
            generateAnswer("custom_report_question", editQuestionId)
          }
          disabled={!editQuestionId || isGenerating}
        >
          Generate answer
        </Button>
        {edited ? (
          <Button variant="contained" onClick={onSave}>
            Save
          </Button>
        ) : (
          <Button variant="contained" onClick={closeClick}>
            Ok
          </Button>
        )}
      </div>
    </Modal>
  );
};

ModalCreateQuestion.propTypes = {
  params: PropTypes.shape({
    editQuestionId: PropTypes.string,
    parentTopicId: PropTypes.string,
    addItem: PropTypes.func,
    generateAnswer: PropTypes.func,
    close: PropTypes.func,
    changeQuestion: PropTypes.func,
    saveDraftData: PropTypes.func,
    isOpen: PropTypes.bool,
    isGenerating: PropTypes.bool,
  }),
};

export default ModalCreateQuestion;
