/* eslint-disable no-unused-vars, camelcase */
import clsx from "clsx";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";

import { TextInput } from "tt-ui-kit";
import { Grid, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as GeneratingIco } from "../../../assets/icons/generating.svg";
import { ReactComponent as DeleteSvg } from "../../../assets/icons/delete2.svg";
import { ReactComponent as OkSvg } from "../../../assets/icons/ok.svg";
import { ReactComponent as ErrorSvg } from "../../../assets/icons/formError.svg";
import Form from "../../../components/form/Form";
import Pagination from "../../../components/pagination/Pagination";
import { ErrorContext, UserContext } from "../../../context";
import {
  CREATE_CALC_QUESTION,
  UPDATE_CALC_QUESTION,
  DELETE_CALC_QUESTION,
  SET_CALC_ANSWER,
  GET_CALC_QUESTION,
} from "../../../api";
import styles from "./CustomReport.module.scss";
import pageStyles from "../../pages.module.scss";
import ModalCreateQuestion from "./ModalCreateQuestion";
import ModalEditQuestion from "./ModalEditQuestion";

const ttRestHost = process.env.REACT_APP_REST_API;
const statuses = {
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  NONE: "NONE",
};

const CustomReport = ({
  questionIndex,
  setQuestionIndex,
  setQuestionCount,
  setAccordionItems,
  calculatorContainerRef,
}) => {
  const { draftId, user } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const { pathname } = useLocation();
  const { pageNum } = useParams();
  const navigate = useNavigate();

  const [createCalcQuestion] = useMutation(CREATE_CALC_QUESTION);
  const [updateCalcQuestion] = useMutation(UPDATE_CALC_QUESTION);
  const [deleteCalcQuestion] = useMutation(DELETE_CALC_QUESTION);
  const [getCalcQuestion] = useLazyQuery(GET_CALC_QUESTION, {
    variables: {
      calc_tt_draft_id: draftId,
      first: 1,
      page: parseInt(pageNum, 10),
    },
  });

  const [isLoading, setIsLoading] = useState({
    loadingCreate: false,
    loadingUpdate: false,
    loadingDelete: false,
    loadingSetAnswer: false,
    loadingData: false,
  });
  const [questionsList, setQuestionsList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [topTopic, setTopTopic] = useState(null);
  const [paginatorInfo, setPaginatorInfo] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [parentTopicId, setParentTopicId] = useState(null);
  const [isQuestionEditModalOpen, setIsQuestionEditModalOpen] = useState(false);
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] =
    useState(false);

  const getIsLoading = () => Object.values(isLoading).some((v) => v);

  // useEffect(() => {
  //   console.log({ questionsList, topicsList });
  // }, [questionsList, topicsList]);

  const setCurrentLoading = (name, value) => {
    setIsLoading((prev) => ({ ...prev, [name]: value }));
  };

  const closeQuestionsModal = () => {
    setIsQuestionCreateModalOpen(false);
    setIsQuestionEditModalOpen(false);
    setEditQuestionId(null);
  };

  const openQuestionModal = (id, action) => {
    if (action === "create") {
      setParentTopicId(id);
      setIsQuestionCreateModalOpen(true);
    } else {
      setEditQuestionId(id);
      setIsQuestionEditModalOpen(true);
    }
  };

  // useEffect(() => {
  //   if (!deleteCalcData?.deleteCalcQuestions) return;
  //   getQuestions();
  // }, [deleteCalcData]);

  const addItem = (addArgs) => {
    const { typeItem, parentId, payload, isNavigate } = addArgs;
    setCurrentLoading("loadingCreate", true);
    createCalcQuestion({
      variables: {
        input: {
          calc_tt_draft_id: draftId,
          data: JSON.stringify({
            type: typeItem,
            parent_topic_id: parentId,
            ...payload,
          }),
        },
      },
    })
      .then((newData) => {
        const { id, type, parent_topic_id } = JSON.parse(
          newData?.data.createCalcQuestion.data,
        );
        if (type === "question") {
          const newQuestions = [
            ...questionsList,
            {
              id,
              custom_report_topic_id: parent_topic_id,
              question_text: payload?.question_text,
            },
          ];
          setQuestionsList(newQuestions);
          setEditQuestionId(id);
        }
        if (type === "topic") {
          const newTopics = [...topicsList, { id, parent_topic_id }];
          setTopicsList(newTopics);
          setTopTopic(newTopics.find((topic) => !topic.parentId));
        }
      })
      .catch((err) => {
        setErrorAlert(err.message);
      })
      .finally(() => {
        if (isNavigate) {
          const url = pathname.replace(
            pageNum,
            parseInt(paginatorInfo.total, 10) + 1,
          );
          navigate(url);
        }
        setCurrentLoading("loadingCreate", false);
      });
  };

  const getQuestions = () => {
    setCurrentLoading("loadingData", true);
    getCalcQuestion()
      .then((calcData) => {
        const { data } = calcData;
        if (!data?.getCalcQuestions?.data) return;
        const { questions, topics } = JSON.parse(data?.getCalcQuestions?.data);
        if (!topics?.length) {
          addItem({ typeItem: "topic" });
        } else {
          setQuestionsList([...questions]);
          setTopicsList(topics);
          setTopTopic(topics.find((topic) => !topic.parent_topic_id));
          setPaginatorInfo(data?.getCalcQuestions.paginatorInfo);
        }
      })
      .catch((err) => {
        setErrorAlert(err.message);
      })
      .finally(() => setCurrentLoading("loadingData", false));
  };

  useEffect(() => {
    getQuestions();
  }, [pageNum]);

  const deleteItem = (type, itemId) => {
    setCurrentLoading("loadingDelete", true);
    deleteCalcQuestion({
      variables: {
        input: {
          calc_tt_draft_id: draftId,
          data: JSON.stringify({ type, id: itemId }),
        },
      },
    })
      .catch((err) => {
        setErrorAlert(err.message);
      })
      .finally(() => {
        getQuestions();
        setCurrentLoading("loadingDelete", false);
      });
  };

  const refreshItems = (questions = [], topics = []) => {
    const newQuestions = questionsList.map((q) => {
      const newQ = questions.find((newQuestion) => newQuestion.id === q.id);
      return {
        ...q,
        edited: newQ ? false : q.edited,
      };
    });
    setQuestionsList([...newQuestions]);
    const newTopics = topicsList.map((t) => {
      const newT = topics.find((newTopic) => newTopic.id === t.id);
      return {
        ...t,
        edited: newT ? false : t.edited,
      };
    });
    setTopicsList([...newTopics]);
  };

  /* update json data example:
    {
        "calc_tt_draft_id": "33333333-4306-4ad9-a5e8-f2b5e42044e9",
        "data": {
            topics: [
                { id: "e7c23702-0064-4518-9fa5-4b071f61cbaa", topic_text: "text2" }
            ],
            questions: [
                { id: "10a745f0-c60f-483d-9403-e335cb638ae2", question_text: "text" }
                { id: "7810f57c-028e-47af-848c-d49af98e2b02", question_text: "text1", answer_text: "text1" },
            ]
        }
    }
    */
  const saveDraftData = (filterId = null) => {
    setCurrentLoading("loadingSave", true);
    updateCalcQuestion({
      variables: {
        input: {
          calc_tt_draft_id: draftId,
          data: JSON.stringify({
            topics: topicsList.filter((t) => t.edited),
            questions: questionsList.filter(
              (q) => q.edited && (filterId == null || filterId === q.id),
            ),
          }),
        },
      },
    })
      .then((calcData) => {
        const { data } = calcData;
        if (!data?.updateCalcQuestions?.data) return;

        const { questions, topics } = JSON.parse(
          data?.updateCalcQuestions?.data,
        );
        refreshItems(questions, topics);
      })
      .catch((err) => {
        setErrorAlert(err.message);
      })
      .finally(() => setCurrentLoading("loadingSave", false));
  };

  const onNextClick = () => {
    const url = pathname.replace(pageNum, parseInt(pageNum, 10) + 1);
    navigate(url);
  };
  const onFinishClick = () => {
    navigate("/drafts");
  };
  const onBackClick = () => {
    const url = pathname.replace(pageNum, parseInt(pageNum, 10) - 1);
    navigate(url);
  };

  const changeTopic = (e, topicId) => {
    const newTopics = topicsList.map((topic) =>
      topic.id !== topicId
        ? topic
        : {
            ...topic,
            topic_text: e.target.value,
            edited: true,
          },
    );
    setTopicsList([...newTopics]);
  };

  const changeQuestion = (newQuestion) => {
    const newQuestions = questionsList.map((question) =>
      question.id !== newQuestion.id
        ? question
        : {
            ...question,
            ...newQuestion,
            edited: true,
          },
    );
    setQuestionsList([...newQuestions]);
  };

  const getText = (type, id) => {
    if (type === "topic") {
      return topicsList.find((topic) => topic.id === id)?.topic_text ?? "";
    }
    if (type === "question") {
      return (
        questionsList.find((question) => question.id === id)?.question_text ??
        ""
      );
    }
    return "";
  };

  // console.log({ q: questionsList.find((question) => question.edited) });

  const unsaved =
    topicsList.some((topic) => topic.edited) ||
    questionsList.some((question) => question.edited);

  // source_id=0daae954-c373-4304-b89e-0e3c04a0937f&source=custom_report_question
  const generateAnswer = (source, id) => {
    const token = Cookies.get("access_token");
    const headers = {
      authorization: token ? `Bearer ${token}` : "",
      Userid: user.id,
    };
    const params = {
      source_id: id,
      source,
    };
    const url = `${ttRestHost}/tt-report-generator/answers/generate`;

    axios
      .get(url, { headers, params })
      .then((response) => {
        const { data } = response;
        if (data?.success) {
          const newQuestion = questionsList.find(
            (question) => question.id === id,
          );
          changeQuestion({ ...newQuestion, state: statuses.PROCESSING });
        }
      })
      .catch((error) => {
        const newQuestion = questionsList.find(
          (question) => question.id === id,
        );
        changeQuestion({ ...newQuestion, state: statuses.ERROR });
      });
  };

  const getList = (id) => {
    const list = questionsList?.filter(
      (question) =>
        question.custom_report_topic_id === id && !!question.question_text,
    );
    return list;
  };

  const getQuestionIco = (question) => {
    switch (question.state) {
      case statuses.PROCESSING:
        return <GeneratingIco className={pageStyles.rotating} />;
      case statuses.SUCCESS:
        return <OkSvg />;
      case statuses.ERROR:
        return <ErrorSvg />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <ModalCreateQuestion
        params={{
          isOpen: isQuestionCreateModalOpen,
          close: closeQuestionsModal,
          isGenerating:
            questionsList.find((q) => q.id === editQuestionId)?.state ===
            statuses.PROCESSING,
          editQuestionId,
          parentTopicId,
          addItem,
          changeQuestion,
          saveDraftData,
          generateAnswer,
        }}
      />
      <ModalEditQuestion
        params={{
          isOpen: isQuestionEditModalOpen,
          close: closeQuestionsModal,
          isGenerating:
            questionsList.find((q) => q.id === editQuestionId)?.state ===
            statuses.PROCESSING,
          question: questionsList.find((q) => q.id === editQuestionId) ?? {},
          topicText: getText("topic", parentTopicId),
          deleteItem,
          changeQuestion,
          saveDraftData,
          generateAnswer,
        }}
      />
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.formContainer}
      >
        <Form>
          {topTopic && (
            <>
              <div className={styles.rowContainer}>
                <TextInput
                  name="topic"
                  label="Topic"
                  className={styles.questionInput}
                  inputProps={{ maxLength: 600 }}
                  value={getText("topic", topTopic.id)}
                  onChange={(e) => changeTopic(e, topTopic.id)}
                  multiline
                  maxRows={8}
                  minRows={1}
                />
                <div className={styles.buttonBlock}>
                  <Button
                    variant="text"
                    onClick={() =>
                      addItem({
                        typeItem: "topic",
                        parentId: topTopic.id,
                      })
                    }
                    startIcon={<AddIcon />}
                    className={styles.questionButton}
                    disabled={getIsLoading()}
                  >
                    Add subtopic
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => openQuestionModal(topTopic.id, "create")}
                    startIcon={<AddIcon />}
                    className={styles.questionButton}
                    disabled={getIsLoading()}
                  >
                    Add question
                  </Button>
                </div>
              </div>
              <div className={styles.childBlock}>
                {getList(topTopic.id).length > 0 && (
                  <div className={styles.questionBlock}>
                    {getList(topTopic.id).map((question) => (
                      <div key={question.id} className={styles.rowContainer}>
                        <div>{question.question_text}</div>
                        <div className={styles.buttonBlock}>
                          {getQuestionIco(question)}
                          <Button
                            variant="outlined"
                            onClick={() => openQuestionModal(question.id)}
                            disabled={getIsLoading()}
                            className={styles.questionButton}
                          >
                            View more
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {topicsList
                  ?.filter((topic) => topic.parent_topic_id === topTopic.id)
                  .map((topic) => (
                    <div key={topic.id}>
                      <div className={styles.rowContainer}>
                        <TextInput
                          name="subtopic"
                          label="Subtopic"
                          className={styles.questionInput}
                          inputProps={{ maxLength: 600 }}
                          value={getText("topic", topic.id)}
                          onChange={(e) => changeTopic(e, topic.id)}
                          multiline
                          maxRows={8}
                          minRows={1}
                        />
                        <div className={styles.buttonBlock}>
                          <IconButton
                            onClick={() => deleteItem("topic", topic.id)}
                            className={styles.questionButton}
                            disabled={getIsLoading()}
                          >
                            <DeleteSvg fontSize="small" />
                          </IconButton>
                          <Button
                            variant="text"
                            onClick={() =>
                              openQuestionModal(topic.id, "create")
                            }
                            startIcon={<AddIcon />}
                            className={styles.questionButton}
                            disabled={getIsLoading()}
                          >
                            Add question
                          </Button>
                        </div>
                      </div>
                      {getList(topic.id).length > 0 && (
                        <div key={topic.id} className={styles.childBlock}>
                          <div className={styles.questionBlock}>
                            {getList(topic.id).map((question) => (
                              <div
                                key={question.id}
                                className={styles.rowContainer}
                              >
                                <div className={styles.text}>
                                  {question.question_text}
                                </div>
                                <div className={styles.buttonBlock}>
                                  {getQuestionIco(question)}
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      openQuestionModal(question.id)
                                    }
                                    disabled={getIsLoading()}
                                    className={styles.questionButton}
                                  >
                                    View more
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}
        </Form>
        <div className={styles.navigation}>
          <div className={styles.pageInfo}>
            <Pagination
              activePage={paginatorInfo?.currentPage ?? 1}
              pagesCount={paginatorInfo?.total ?? 1}
            />
          </div>
          <div className={styles.footerButtons}>
            <Button
              variant="outlined"
              onClick={onBackClick}
              disabled={!paginatorInfo || paginatorInfo?.currentPage <= 1}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              onClick={() => generateAnswer("custom_report_topic", topTopic.id)}
              disabled={
                !questionsList.some(
                  (q) => !!q.question_text && !q.state !== statuses.PROCESSING,
                )
              }
            >
              Generate answer
            </Button>
            {paginatorInfo?.total === paginatorInfo?.currentPage && (
              <Button
                variant="outlined"
                onClick={() => addItem({ typeItem: "topic", isNavigate: true })}
                disabled={
                  unsaved || paginatorInfo?.total !== paginatorInfo?.currentPage
                }
              >
                Add topic
              </Button>
            )}
            {unsaved ? (
              <Button variant="contained" onClick={saveDraftData}>
                Save
              </Button>
            ) : (
              <>
                {paginatorInfo?.currentPage < paginatorInfo?.total && (
                  <Button
                    variant="contained"
                    onClick={onNextClick}
                    disabled={getIsLoading()}
                  >
                    Next
                  </Button>
                )}
                {paginatorInfo?.currentPage >= paginatorInfo?.total && (
                  <Button
                    variant="contained"
                    onClick={onFinishClick}
                    disabled={getIsLoading()}
                  >
                    Finish
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

CustomReport.propTypes = {
  setQuestionIndex: PropTypes.func,
  questionIndex: PropTypes.number,
  setQuestionCount: PropTypes.func,
  setAccordionItems: PropTypes.func,
  calculatorContainerRef: PropTypes.any,
};

export default CustomReport;
