import clsx from "clsx";
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, FormControlLabel, Checkbox } from "@mui/material";

import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";

import { ReactComponent as CloseSvg } from "../../assets/icons/close.svg";
import styles from "./CalcModal.module.scss";

const CalcModal = ({
  opened,
  children,
  headerContent,
  backBtnText,
  okBtnText,
  closeModalFunc,
  backFunc,
  okFunc,
  needAgreement,
  okBtnDisabled,
  blockOutsideClick,
  containedStyle,
}) => {
  const [isAgreed, setIsAgreed] = useState(!needAgreement);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const contentEl = useRef(null);

  const onCloseClick = () => {
    if (!blockOutsideClick) closeModalFunc();
  };

  const onBackClick = () => {
    closeModalFunc();
    backFunc();
  };

  const onOkClick = () => {
    closeModalFunc();
    okFunc();
  };

  const scrolledToEnd = (e) => {
    const { target } = e;

    setIsCheckboxEnabled(
      target.offsetHeight + Math.ceil(target.scrollTop) >=
        target.scrollHeight * 0.98,
    );
  };

  useEffect(() => {
    const { current } = contentEl;

    setIsCheckboxEnabled(
      current
        ? current.offsetHeight + Math.ceil(current.scrollTop) >=
            current.scrollHeight
        : false,
    );
  }, [opened]);

  return (
    <>
      {opened && (
        <div className={styles.modal}>
          <div className={styles.cover} onClick={onCloseClick} />
          <div className={styles.container}>
            <div className={styles.headerContainer}>
              <span className={styles.header}>{headerContent}</span>
              <Button
                variant="text"
                className={styles.closeBtn}
                onClick={onCloseClick}
              >
                <CloseSvg className={styles.closeSvg} />
              </Button>
            </div>
            <div
              className={styles.content}
              ref={contentEl}
              onScroll={scrolledToEnd}
            >
              {children}
            </div>
            <div className={styles.agreeCheck}>
              <div className={styles.checkboxContainer}>
                {needAgreement && (
                  <FormControlLabel
                    className={styles.checkbox}
                    control={
                      <Checkbox
                        onChange={() => setIsAgreed(!isAgreed)}
                        checkedIcon={<CheckBoxOutlined />}
                        variant="unlighted"
                        disabled={!isCheckboxEnabled}
                      />
                    }
                    label="I have read the terms and conditions and agree to them"
                    labelPlacement="end"
                  />
                )}
              </div>
              <div
                className={clsx(styles.buttons, backBtnText ? styles.wide : "")}
              >
                {backBtnText && (
                  <Button onClick={onBackClick} variant="outlined">
                    {backBtnText}
                  </Button>
                )}
                <Button
                  onClick={onOkClick}
                  disabled={!isAgreed || okBtnDisabled}
                  variant={containedStyle || backBtnText ? "contained" : "text"}
                >
                  {okBtnText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CalcModal.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.node,
  headerContent: PropTypes.string,
  backBtnText: PropTypes.string,
  okBtnText: PropTypes.string,
  closeModalFunc: PropTypes.func,
  backFunc: PropTypes.func,
  okFunc: PropTypes.func,
  needAgreement: PropTypes.bool,
  okBtnDisabled: PropTypes.bool,
  containedStyle: PropTypes.bool,
  blockOutsideClick: PropTypes.bool,
};

CalcModal.defaultProps = {
  opened: false,
  children: null,
  headerContent: "",
  backBtnText: "",
  okBtnText: "",
  closeModalFunc: () => {},
  backFunc: () => {},
  okFunc: () => {},
  needAgreement: false,
  okBtnDisabled: false,
  containedStyle: false,
  blockOutsideClick: false,
};

export default CalcModal;
