import * as d3 from "d3";
import PropTypes from "prop-types";
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

import styles from "./Chart.module.scss";

const getTotal = (val) =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(val);

const isDesktopSize = (node) =>
  (node.width || node.clientWidth) >= 520 &&
  (node.height || node.clientHeight) >= 520;

// returns text on 1 or 2 lines
const wordwrap = (text) => {
  const words = text.trim().replace("/", "/ ").split(" ");
  const lineLength = 16; // магическое число - желаемое количество символов в строке
  let lines = [];
  const { length } = words.reduce((res, word) => `${res} ${word}`);
  if (words.length > 1) {
    const third = Math.ceil(words.length / Math.ceil(length / lineLength));
    while (words.length > 0) {
      const wordString = [];
      do {
        wordString.push(words.shift());
      } while (wordString.length < third && words.length > 0);
      lines.push(wordString);
    }
    // в цикле происходит проверка: дает ли перенос одного слова уменьшение разницы в длинах строк
    for (let i = 0; i < lines.length - 1; i += 1) {
      const linesCopy = lines.slice();
      const diff1 =
        linesCopy[i].join(" ").length - linesCopy[i + 1].join(" ").length;
      if (diff1 > 0 && linesCopy[i].length > 1 && linesCopy[i + 1].length > 0) {
        const diff2 =
          linesCopy[i + 1].join(" ").length +
          linesCopy[i][linesCopy[i].length - 1].length -
          linesCopy[i].slice(0, linesCopy[i].length - 1).join(" ").length;
        if (diff1 > diff2) linesCopy[i + 1].unshift(linesCopy[i].pop());
      }
      if (diff1 < 0 && linesCopy[i].length > 0 && linesCopy[i + 1].length > 1) {
        const diff2 =
          linesCopy[i + 1].slice(1, linesCopy[i + 1].length).join(" ").length -
          linesCopy[i].join(" ").length +
          linesCopy[i + 1][0].length;
        if (diff1 < diff2) linesCopy[i].push(linesCopy[i + 1].shift());
      }
    }
  } else lines = [words];
  return lines.map((array) => array.join(" "));
};

/* eslint-disable func-names */
const Chart = ({
  chartData,
  hasDesktopFormat,
  onSectorClick,
  onChartClick,
  activeItem,
  score,
  lastIndex,
  isCO2score,
}) => {
  const chartRef = useRef(null);

  const [activeSector, setActiveSector] = useState(activeItem || null);

  const renderChart = (svg, data, scoreValue) => {
    svg.selectAll("*").remove();
    const maxWordLength = !data.length
      ? 140
      : 8 *
        Math.max(
          ...data.map((d) => wordwrap(d.name).map((n) => n.length)).flat(),
        );

    const svgNode = svg.node().getBoundingClientRect();
    const isMobileDevice = !(hasDesktopFormat && isDesktopSize(svgNode));

    svg.classed("clickable", !!onChartClick);
    // common sizes
    const fullWidth =
      svgNode.width < svgNode.height ? svgNode.width : svgNode.height;
    // const fullHeight = svgNode.height;
    const fullHeight = fullWidth; //

    let marginSize = isMobileDevice ? 0 : Math.min(maxWordLength, 140);
    if (!hasDesktopFormat) marginSize = 0;

    const margin = {
      top: marginSize,
      right: marginSize,
      bottom: marginSize,
      left: marginSize,
    };

    const width = fullWidth - margin.left - margin.right;
    const height = fullHeight - margin.top - margin.bottom;
    const textMargin = Math.min(width, height) * 0.05;
    const baseSize = Math.min(width - textMargin * 2, height - textMargin * 2);
    const optimalLabelBase = Math.max(320, baseSize);

    // chart sizes
    const innerRadius = Math.max(baseSize * 0.2, 55);
    const betweenBarCirclesMargin = baseSize * 0.03;
    const textCircleRadius = innerRadius * 0.85;
    const miniCirclesRadius = 4;
    const outerCircleWidth = miniCirclesRadius * 0.66;
    const outerCircleRadius = baseSize / 2 - miniCirclesRadius;
    const outerRadius = outerCircleRadius - betweenBarCirclesMargin;
    const underOuterCircleRadius = outerCircleRadius - betweenBarCirclesMargin;
    const activeSectorCircleRadius =
      outerCircleRadius - betweenBarCirclesMargin * 0.8;

    // initial bars size
    const minBarSize = 1.5;

    // X scale
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI])
      .domain(data.map((d) => d.name));

    // circles width shadows
    svg
      .append("g")
      .classed(styles.shadowedBaseSheet, true)
      .attr("transform", `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .each(function () {
        // circle with inner shadow
        d3.select(this)
          .append("circle")
          .attr("r", underOuterCircleRadius)
          .attr("fill", `url(#${styles.gradient})`);

        // gradient for inner-shadowed circle
        d3.select(this)
          .append("radialGradient")
          .attr("id", styles.gradient)
          .each(function () {
            d3.select(this)
              .append("stop")
              .attr("offset", "75%")
              .attr("stop-color", "#fff");
            d3.select(this)
              .append("stop")
              .attr("offset", "82%")
              .attr("stop-color", "#fdfdfd");
            d3.select(this)
              .append("stop")
              .attr("offset", "95%")
              .attr("stop-color", "#f9f9f9");
            d3.select(this)
              .append("stop")
              .attr("offset", "100%")
              .attr("stop-color", "#f3f3f3");
          });

        // score circle
        d3.select(this)
          .append("circle")
          .classed(styles.scoreCircle, true)
          .attr("r", textCircleRadius);
        // score text
        if (scoreValue && !onChartClick) {
          d3.select(this)
            .append("text")
            .classed(styles.scoreTextContainer, true)
            .each(function () {
              d3.select(this)
                .append("tspan")
                .classed(styles.scoreValue, true)
                .attr("dy", -innerRadius * 0.1)
                .attr("x", 0)
                .attr("dominant-baseline", "middle")
                .attr("text-anchor", "middle")
                .attr("font-size", innerRadius * 0.5)
                .text(getTotal(scoreValue));
              d3.select(this)
                .append("tspan")
                .classed(styles.scoreUnits, true)
                .attr("dy", innerRadius * 0.55)
                .attr("x", 0)
                .attr("dominant-baseline", "middle")
                .attr("text-anchor", "middle")
                .attr("font-size", innerRadius * 0.2)
                .text(isCO2score ? "kg CO2e" : "score");
            });
          d3.select(this)
            .append("line")
            .style("stroke", "lightgray")
            .style("stroke-width", 2)
            .attr("x1", -innerRadius * 0.7)
            .attr("y1", innerRadius * 0.19)
            .attr("x2", innerRadius * 0.7)
            .attr("y2", innerRadius * 0.19);
        } else if (onChartClick) {
          d3.select(this)
            .append("text")
            .classed(styles.scoreText, true)
            .each(function () {
              d3.select(this)
                .append("tspan")
                .attr("dy", 0)
                .attr("x", 0)
                .text("more...");
            });
        } else {
          d3.select(this)
            .append("text")
            .classed(styles.scoreTextContainer, true)
            .each(function () {
              d3.select(this)
                .append("tspan")
                .attr("dy", -innerRadius * 0.15)
                .attr("x", 0)
                .attr("dominant-baseline", "middle")
                .attr("text-anchor", "middle")
                .attr("font-size", innerRadius * 0.3)
                .text("ESG");
              d3.select(this)
                .append("tspan")
                .attr("dy", innerRadius * 0.4)
                .attr("x", 0)
                .attr("dominant-baseline", "middle")
                .attr("text-anchor", "middle")
                .attr("font-size", innerRadius * 0.3)
                .text("score");
            });
        }
      });

    // add grey bars
    svg
      .append("g")
      // .classed(styles.barChartSheet, true)
      .attr("transform", `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .classed(styles.transparent, true)
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle((d) => x(d.name))
          .endAngle((d) => x(d.name) + x.bandwidth())
          .padAngle(0.04)
          .padRadius(innerRadius),
      )
      .classed("clickable", true)
      .on("click", (e, bar) => {
        const active = bar.name === activeSector ? null : bar.name;
        setActiveSector(active);

        d3.selectAll(`.${styles.barChartSheet}`)
          .selectAll("path")
          .attr("opacity", (d) => (!active || d.name === active ? 1 : 0.4));

        if (onSectorClick) onSectorClick(active);
      });

    // add color bars
    svg
      .append("g")
      .classed(styles.barChartSheet, true)
      .attr("transform", `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .selectAll("path")
      .data(data)
      .enter()
      .append("path")
      .classed(styles.bar, true)
      .attr("fill", (d) => d.color)
      .attr("opacity", (d) =>
        !activeSector || d.name === activeSector ? 1 : 0.4,
      )
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(
            (d) =>
              innerRadius +
              Math.max(
                minBarSize,
                Math.round(
                  (d.value / d.total) *
                    ((d.name === activeSector
                      ? activeSectorCircleRadius
                      : outerRadius) -
                      innerRadius),
                ),
              ),
          )
          .startAngle((d) => x(d.name))
          .endAngle((d) => x(d.name) + x.bandwidth())
          .padAngle(0.04)
          .padRadius(innerRadius),
      )
      .classed("clickable", true)
      .on("click", (e, bar) => {
        const active = bar.name === activeSector ? null : bar.name;
        setActiveSector(active);

        d3.selectAll(`.${styles.barChartSheet}`)
          .selectAll("path")
          .attr("opacity", (d) => (!active || d.name === active ? 1 : 0.4));

        if (onSectorClick) onSectorClick(active);
      });

    // add grid lines
    if (chartData.length === 1) {
      svg
        .append("g")
        .classed(styles.chartGrid, true)
        .attr("transform", `translate(${fullWidth / 2}, ${fullHeight / 2})`)
        .selectAll("circle")
        .data([...Array(chartData[0].value).keys()])
        .enter()
        .append("circle")
        .attr(
          "r",
          (d) =>
            innerRadius +
            ((d + 1) / chartData[0].total) * (outerRadius - innerRadius),
        )
        .attr("stroke", "#fff")
        .attr("cx", 0)
        .attr("cy", 0);
    }

    // add outer circle
    svg
      .append("g")
      .classed("outer-circle-sheet", true)
      .attr("transform", `translate(${fullWidth / 2}, ${fullHeight / 2})`)
      .each(function () {
        // add thin circle
        d3.select(this)
          .selectAll("path")
          .data(data)
          .enter()
          .append("path")
          .classed("thin-circle-bar", true)
          .attr(
            "transform",
            `rotate(${(x.bandwidth() * 180) / (Math.PI * 2)},0,0)`,
          )
          .attr("fill", (d, i) =>
            (i > lastIndex && data.length > 1) || !hasDesktopFormat
              ? "transparent"
              : d.color,
          )
          .attr(
            "d",
            d3
              .arc()
              .innerRadius(outerCircleRadius - outerCircleWidth / 2)
              .outerRadius(() => outerCircleRadius + outerCircleWidth / 2)
              .startAngle((d) => x(d.name))
              .endAngle((d) => x(d.name) + x.bandwidth())
              .padAngle(0)
              .padRadius(outerRadius + betweenBarCirclesMargin),
          );

        // add mini-circles
        if (data.length > 1) {
          d3.select(this)
            .selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .classed("mini-circle", true)
            .attr("r", miniCirclesRadius)
            .attr("fill", (d) => d.color)
            .attr(
              "cx",
              (d) =>
                outerCircleRadius * Math.sin(x(d.name) + x.bandwidth() / 2),
            )
            .attr(
              "cy",
              (d) =>
                -outerCircleRadius * Math.cos(x(d.name) + x.bandwidth() / 2),
            );
        }
      });

    // add text labels
    if (!isMobileDevice) {
      svg
        .append("g")
        .classed(styles.textLabelsSheet, true)
        .attr("transform", `translate(${fullWidth / 2}, ${fullHeight / 2})`)
        .selectAll("text")
        .data(data)
        .enter()
        .append("g")
        .attr("text-anchor", (d) =>
          x(d.name) + x.bandwidth() / 2 >= Math.PI ? "end" : "start",
        )
        .attr(
          "transform",
          (d) =>
            `rotate(${
              ((x(d.name) + x.bandwidth() / 2) * 180) / Math.PI - 90
            }) translate(${outerCircleRadius + textMargin},0)`,
        )
        .append("text")
        .attr("transform", (d) =>
          x(d.name) + x.bandwidth() / 2 >= Math.PI
            ? "rotate(180)"
            : "rotate(0)",
        )
        .each(function (d) {
          const lines = wordwrap(d.name);
          if (lines.length === 1) {
            d3.select(this)
              .append("tspan")
              .attr("dy", 5)
              .attr("x", 0)
              .text(lines);
          } else if (lines.length === 2) {
            d3.select(this)
              .append("tspan")
              .attr("dy", -optimalLabelBase * 0.02)
              .attr("x", 0)
              .text(lines[0]);
            d3.select(this)
              .append("tspan")
              .attr("dy", optimalLabelBase * 0.05)
              .attr("x", 0)
              .text(lines[1]);
          } else if (lines.length === 3) {
            d3.select(this)
              .append("tspan")
              .attr("dy", -optimalLabelBase * 0.04)
              .attr("x", 0)
              .text(lines[0]);
            d3.select(this)
              .append("tspan")
              .attr("dy", optimalLabelBase * 0.05)
              .attr("x", 0)
              .text(lines[1]);
            d3.select(this)
              .append("tspan")
              .attr("dy", optimalLabelBase * 0.05)
              .attr("x", 0)
              .text(lines[2]);
          }
        });
    }
  };

  useLayoutEffect(() => {
    const chartElem = d3.select(chartRef.current);
    const onResize = () => {
      renderChart(chartElem, chartData, !score ? null : score);
    };

    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  useEffect(() => {
    setActiveSector(activeItem || null);
  }, [activeItem]);

  return (
    <svg
      className={styles.circularBarplot}
      ref={chartRef}
      onClick={onChartClick}
    />
  );
};
/* eslint-enable func-names */

Chart.propTypes = {
  chartData: PropTypes.instanceOf(Array),
  hasDesktopFormat: PropTypes.bool,
  activeItem: PropTypes.string,
  lastIndex: PropTypes.number,
  score: PropTypes.string,
  isCO2score: PropTypes.bool,
  onSectorClick: PropTypes.func,
  onChartClick: PropTypes.func,
};

Chart.defaultProps = {
  chartData: [],
  hasDesktopFormat: true,
  activeItem: null,
  lastIndex: -1,
  score: null,
  isCO2score: false,
};

export default Chart;
