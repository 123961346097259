import PropTypes from "prop-types";
import clsx from "clsx";
import React from "react";
import styles from "./index.module.scss";
import UiTextField from "../../../components/uiTextField/UiTextField";

const StringQuestion = ({ name, text, answer, onChange }) => (
  <>
    <p>{text}</p>
    <UiTextField
      type="text"
      className={clsx(styles.nativeReactTextInput, styles.questionInput)}
      name={name}
      value={answer || ""}
      onChange={onChange}
      inputProps={{ maxLength: 600 }}
    />
  </>
);

StringQuestion.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  answer: PropTypes.string,
  onChange: PropTypes.func,
  onBlurInput: PropTypes.func,
};

export default StringQuestion;
