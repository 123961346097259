import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "@mui/material";
import { TextInput } from "tt-ui-kit";
import UiDatePicker from "../../components/uiDatePicker/UiDatePicker";

import styles from "./SecondStep.module.scss";
import { ReactComponent as ConfDocSvg } from "../../assets/icons/confDoc.svg";
import MuiSelect from "../../components/muiSelect/MuiSelect";

const languages = {
  en: "English",
  de: "Deutsch",
  fr: "Français",
  it: "Italiano",
  es: "Español",
};

const RegistrationCustom = ({
  onChange,
  openDisclaimer,
  openFileModal,
  data,
  // years,
  dateDisable,
}) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Basic details*
    </Button>
    <TextInput
      className={clsx(styles.input, styles.wide)}
      label="Name *"
      name="name"
      value={((data && data.name) ?? "").toString()}
      onChange={onChange}
    />
    <MuiSelect
      className={clsx(styles.input, styles.wide)}
      label="Language"
      name="language"
      value={data.language || "en"}
      onChange={onChange}
      renderValue={(ln) => languages[ln]}
      items={Object.keys(languages) || []}
      disabled={dateDisable}
    />
    <UiDatePicker
      label="Reporting date"
      name="reportDate"
      onChange={onChange}
      value={(data && data.reportDate) ?? null}
      disabled={dateDisable}
      minDate={new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      className={clsx(styles.input, styles.wide)}
      required={true}
      clearable={true}
    />
    <Button
      className={clsx(styles.docBtn, styles.normal)}
      startIcon={<ConfDocSvg />}
      onClick={openFileModal}
    >
      CONFIRMATION DOC
    </Button>
  </>
);

RegistrationCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  openFileModal: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  data: PropTypes.object,
  dateDisable: PropTypes.bool,
};

export default RegistrationCustom;
