/* eslint-disable no-unused-vars */
import clsx from "clsx";
import React, { useContext, useState, useEffect } from "react";
import { v1 as uuidv1 } from "uuid";
import { useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import axios from "axios";

import { IconButton, Input } from "@mui/material";
import { Button, Modal, TextInput } from "tt-ui-kit";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import BlockIcon from "@mui/icons-material/Block";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DropArea from "./DropArea";

import { ReactComponent as DropBoxSvgG } from "../../assets/icons/dropBoxIcoGray.svg";
import { ReactComponent as GdriveSvgG } from "../../assets/icons/gdriveIcoGray.svg";
import { ReactComponent as IcloudSvgG } from "../../assets/icons/iCloudIcoGray.svg";
import { ReactComponent as ConfDocSvg } from "../../assets/icons/confDoc.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";

import { getFileType, validateFile } from "../../utils";
import UserContext from "../../context/User/userContext";
import CalculatorsContext from "../../context/Calculators/calculatorsContext";
import styles from "./FileUploader.module.scss";

import {
  DELETE_QUESTIONS_FILE,
  GET_URL_FOR_PRIVATE_UPLOAD,
  ADD_UPLOADED_FILE_TO_QUESTION,
  GET_QUESTIONS_FILE,
  EDIT_QUESTIONS_FILES,
} from "../../api";

// const NAME_LENGTH_LIMIT = 14;
// const SIZE_LIMIT = 2048000;

const icoStyle = { width: 20, height: 20, flexShrink: 0, fontSize: 20 };

const STATUSES = {
  UPLOADING_STATUS: "uploading",
  UPLOADED_STATUS: "uploaded",
  ERROR_STATUS: "error",
};

const FileUploader = ({
  questionCode,
  disabled,
  btnType,
  btnText,
  title,
  isMultiple,
}) => {
  const { draftId } = useContext(UserContext);
  const { calculatorName } = useContext(CalculatorsContext);
  const [deleteFile] = useMutation(DELETE_QUESTIONS_FILE);
  const [getPrivateUrl] = useMutation(GET_URL_FOR_PRIVATE_UPLOAD);
  const [uploadFileToQuestion] = useMutation(ADD_UPLOADED_FILE_TO_QUESTION);
  const [editFiles] = useMutation(EDIT_QUESTIONS_FILES);
  const [getFiles, { data: filesData }] = useLazyQuery(GET_QUESTIONS_FILE, {
    variables: { input: { draft_id: draftId, questions_code: questionCode } },
  });

  const [fileToDelete, setFileToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const [viewDescriptionId, setViewDescriptionId] = useState(null);
  const [idsForSave, setIdsForSave] = useState([]);

  useEffect(() => {
    if (!filesToUpload?.length) getFiles();
  }, [filesToUpload]);

  useEffect(() => {
    setFilesList([]);
  }, [questionCode]);

  useEffect(() => {
    if (!filesData?.getQuestionsFile) return;
    const rejectedFiles = filesList.filter(
      (file) => file.status === STATUSES.ERROR_STATUS,
    );
    const savedFiles = filesData.getQuestionsFile;
    setFilesList([
      ...rejectedFiles.map((f) => ({ ...f, status: STATUSES.ERROR_STATUS })),
      ...savedFiles.map((f) => ({ ...f, status: STATUSES.UPLOADED_STATUS })),
    ]);
  }, [filesData]);

  const updateState = (uuidName, status) => {
    const updatedFile = filesList.find((file) => file.uuidName === uuidName);
    return { ...updatedFile, status };
  };

  const uploadFile = async (file) => {
    try {
      const privateUrl = await getPrivateUrl({
        variables: {
          input: {
            type: file.type,
            name: file.uuidName,
            size: `${file.f.size}`,
          },
        },
      });
      const url = privateUrl.data?.getUrlForPrivateUpload?.url;
      if (!url) throw new Error("no url");
      const putData = await axios.put(url, file.f, {
        headers: {
          "Content-type": file.f.type,
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (putData) {
        // отправка на наш сервак
        const saveResult = await uploadFileToQuestion({
          variables: {
            input: {
              questions_code: questionCode,
              calc_tt_draft_id: draftId,
              type: file.type,
              original_name: file.originalName,
              file: file.uuidName,
              size: `${file.f.size}`,
              description: "",
            },
          },
        });
        if (saveResult) {
          return updateState(file.uuidName, STATUSES.UPLOADED_STATUS);
        }
      }
      return updateState(file.uuidName, STATUSES.ERROR_STATUS);
    } catch (error) {
      return updateState(file.uuidName, STATUSES.ERROR_STATUS);
    }
  };

  const saveQuestionFiles = async () => {
    // поставить экран загрузки
    const arr = [];
    filesToUpload.forEach((file) => {
      arr.push(uploadFile(file, questionCode));
    });
    const updated = await Promise.all(arr);
    // снять экран загрузки
    setFilesList(
      filesList.map((file) =>
        updated.some((ufile) => ufile.uuidName === file.uuidName)
          ? updated.find((ufile) => ufile.uuidName === file.uuidName)
          : file,
      ),
    );
    setFilesToUpload([]);
  };

  const deleteQuestionsFile = async () => {
    try {
      const { data } = await deleteFile({
        variables: {
          id: fileToDelete.id,
        },
      });
      if (data?.deleteQuestionsFile) {
        const actualFilesList = filesList.filter(
          (file) => file.id !== fileToDelete.id,
        );
        setFilesList(actualFilesList);
        setFileToDelete(null);
      }
    } catch (_) {
      // TODO: показывать ошибку, если запрос не прошёл
      // см. комментарий в Local.jsx
    }
  };

  const updateFilesList = (fList) => {
    const newList = [];
    for (let i = 0; i < fList.length; i += 1) {
      newList.push(fList[i]);
    }
    const actualFilesList = newList.reduce((arr, f) => {
      const fileNameSplitted = f.name.split(".");
      const extension = fileNameSplitted[fileNameSplitted.length - 1];
      const uuidName = `${uuidv1()}.${extension}`;
      const newFiles = [
        ...arr,
        {
          f,
          originalName: f.name,
          uuidName,
          status:
            f.error || !validateFile(extension, f.size ?? 0, calculatorName)
              ? STATUSES.ERROR_STATUS
              : STATUSES.UPLOADING_STATUS,
          type: getFileType(extension, calculatorName),
        },
      ];
      return newFiles;
    }, []);
    setFilesList([...actualFilesList, ...filesList]);
    setFilesToUpload([
      ...actualFilesList.filter((f) => f.status === STATUSES.UPLOADING_STATUS),
    ]);
  };

  useEffect(() => {
    if (!filesToUpload.length) return;
    saveQuestionFiles();
  }, [filesToUpload]);

  const closeDeleteModal = () => {
    setFileToDelete(null);
  };

  const removeFileFromList = (file) => {
    if (file?.id) {
      setFileToDelete(file);
    } else {
      const actualFilesList = filesList.filter(
        (f) => f.uuidName !== file.uuidName,
      );
      setFilesList(actualFilesList);
    }
  };

  const onInputChange = (e) => {
    updateFilesList(e.target.files);
  };

  const fileStatusStyle = (file) => {
    if (file.status === STATUSES.ERROR_STATUS) return styles.rejected;
    if (file.status === STATUSES.UPLOADED_STATUS) return styles.accepted;
    return styles.loading;
  };

  const getFileStatusIcon = (file) => {
    if (file.status === STATUSES.ERROR_STATUS) return <BlockIcon />;
    if (file.status === STATUSES.UPLOADED_STATUS) return <TaskOutlinedIcon />;
    return <RestorePageOutlinedIcon />;
  };

  const onExpandClick = (id) =>
    setViewDescriptionId((value) => (value === id ? null : id));

  const onClose = () => {
    setIsModalOpen(false);
  };

  const onSaveFile = () => {
    const filesInput = idsForSave.map((id) => ({
      id,
      description: filesList.find((f) => f.id === id).description,
    }));
    editFiles({
      variables: {
        files: filesInput,
      },
    }).then((res) => setIdsForSave([]));
  };

  const onEditDescription = (file, newDescription) => {
    if (idsForSave.indexOf(file.id) === -1)
      setIdsForSave([...idsForSave, file.id]);

    const updatedFile = { ...file, description: newDescription };
    const updatedFilesList = filesList.map((f) =>
      f.uuidName === file.uuidName ? updatedFile : f,
    );
    setFilesList(updatedFilesList);
  };

  const renderFileBlock = (file) => (
    // <li key={idx} title="validate" className={fileStatusStyle(file)}>
    <div className={styles.fileBlock}>
      <div className={styles.fileInfoBlock}>
        <div className={clsx(styles.rowWrapper, styles.file)}>
          <div className={clsx(styles.rowWrapper, fileStatusStyle(file))}>
            {getFileStatusIcon(file)}
            <div className={styles.fileName}>{file.originalName}</div>
          </div>
        </div>
        <div className={styles.rowWrapper}>
          <IconButton onClick={() => onExpandClick(file.id)}>
            {viewDescriptionId === file.id ? (
              <ExpandLessIcon style={icoStyle} />
            ) : (
              <ExpandMoreIcon style={icoStyle} />
            )}
          </IconButton>
          <IconButton onClick={() => removeFileFromList(file)}>
            <DeleteIcon style={icoStyle} />
          </IconButton>
        </div>
      </div>
      {viewDescriptionId === file.id && (
        <div className={styles.wide}>
          <TextInput
            name="description"
            label="Description"
            className={clsx(styles.questionInput, styles.wide)}
            value={file.description}
            onChange={(e) => onEditDescription(file, e.target.value)}
            multiline
            maxRows={10}
            minRows={3}
            required={false}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      <Button
        type={btnType ?? "link"}
        startIcon={<ConfDocSvg />}
        onClick={() => setIsModalOpen((value) => !value)}
        disabled={disabled}
        className={styles.docButton}
      >
        {btnText ?? "CONFIRMATION DOC"}
      </Button>
      {isModalOpen && (
        <>
          <Modal
            open={!!fileToDelete}
            onClose={closeDeleteModal}
            onClick={(e) => e.stopPropagation()}
            title="Delete file"
            className={styles.modal}
            closeOnlyByControls
          >
            <div style={{ padding: "16px 0 20px 0" }}>
              Do you really want to delete file &quot;
              {fileToDelete?.originalName ?? ""}&quot;?
            </div>
            <div className={styles.navigation}>
              <Button type="defulat" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button type="primary" onClick={deleteQuestionsFile}>
                Delete
              </Button>
            </div>
          </Modal>
          <Modal
            open={isModalOpen}
            onClose={() => onClose(false)}
            onClick={(e) => e.stopPropagation()}
            title={title ?? "Upload your confirmation docs"}
            className={styles.modal}
            closeOnlyByControls
            /* eslint-disable-next-line no-underscore-dangle */
            containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
          >
            <div className={styles.columnWrapper}>
              <div className={styles.rowWrapper}>
                <DropArea onDrop={updateFilesList}></DropArea>
                <div className={styles.linkWrapper}>
                  <Input
                    type="file"
                    className={styles.input}
                    onChange={onInputChange}
                    multiple={isMultiple}
                    id="icon-button-file"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="icon-button-file">
                    <Button
                      type="link"
                      component="span"
                      size="small"
                      startIcon={<FileUploadIcon />}
                    >
                      Choose the file
                    </Button>
                  </label>
                  <Button
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<DropBoxSvgG />}
                    disabled={true}
                  >
                    UPLOAD FROM DROPBOX
                  </Button>
                  <Button
                    className={styles.disabled}
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<GdriveSvgG />}
                    disabled={true}
                  >
                    UPLOAD FROM GOOGLE DRIVE
                  </Button>
                  <Button
                    className={styles.disabled}
                    type="link"
                    component="span"
                    size="small"
                    startIcon={<IcloudSvgG />}
                    disabled={true}
                  >
                    UPLOAD FROM ICLOUD
                  </Button>
                </div>
              </div>
              <div className={clsx(styles.columnWrapper, styles.wide)}>
                <>
                  {filesList.length > 0 ? (
                    <ul>
                      {filesList.map((file, idx) => (
                        <li key={idx} className={styles.wide}>
                          {renderFileBlock(file, idx)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className={styles.noFiles}>No files yet</div>
                  )}
                </>
              </div>
              <div className={styles.navigation}>
                <Button type="defulat" onClick={() => onClose(false)}>
                  Cancel
                </Button>
                {!idsForSave.length && (
                  <Button type="primary" onClick={() => onClose(false)}>
                    Ok
                  </Button>
                )}
                {!!idsForSave.length && (
                  <Button type="primary" onClick={() => onSaveFile()}>
                    Save
                  </Button>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

FileUploader.propTypes = {
  questionCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  btnType: PropTypes.string,
  btnText: PropTypes.string,
  title: PropTypes.string,
  isMultiple: PropTypes.bool,
};

FileUploader.defaultProps = {
  isMultiple: true,
};

export default FileUploader;
